import { Image, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { my_pink, my_pink_2 } from '../../helper/Colors';

export default function CardHome({
    name,
    age,
    city,
    country,
    img,
    itemWidth,
}) {
  return (
        
        <View style={ [styles.container, { width: itemWidth, }] }>
        <View style={ styles.containerImg}>
            <Image source={
                img
            } 
                style={ styles.img }
            />
        </View>
      <View style={ styles.containerActions}>
        <View style={{ backgroundColor: '#FFFFFF', borderWidth: 1, borderRightWidth: 3,borderBottomWidth: 2, borderRadius: 8, padding: 8, }}>
            <MaterialCommunityIcons name='close' color={'black'} size={40} />
        </View>
        <View style={{ backgroundColor: my_pink_2, borderWidth: 1, borderRightWidth: 3,borderBottomWidth: 2, borderRadius: 8, padding: 8, }}>
            <MaterialCommunityIcons name='heart' color={'white'} size={40} />
        </View>
      </View>

      <View style={ styles.containerInfo }>
        <View>
            <View><Text style={{ fontSize: 24, fontWeight: '600', marginBottom: 10, }}>{name}, {age}</Text></View>
            <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                <MaterialIcons name='location-on' color={'black'} size={24} />
                <Text style={{ fontSize: 14, }}>{city}, {country}</Text>
            </View>
        </View>

        <View>
            <View style={{ borderWidth: 1, borderRightWidth: 3,borderBottomWidth: 2, borderRadius: 60, width: 46, height: 46, justifyContent: 'center', alignItems: 'center' }}>
                <FontAwesome5 name='arrow-down' color={'black'} size={24} />
            </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        paddingTop: 20,
        paddingBottom: 25,
        paddingHorizontal: 20,
        borderRadius: 18,
        borderRightWidth: 7,
        borderBottomWidth: 4,
        backgroundColor: my_pink,
        marginHorizontal: 10,
    },
    containerImg: {
        height: 513,
        borderRadius: 8,
        borderWidth: 1,
        overflow: 'hidden',
    },
    img: {
        height: '100%',
        width: '100%',
        resizeMode: 'cover'
    },
    containerActions: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: -45,
        marginBottom: 15,
    },
    containerInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    }
})