import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { my_yellow_3 } from '../../helper/Colors';

const ChatBubbleRight = ({ message }) => {
  return (
    <View style={{ alignItems: 'flex-end', marginTop: 10,}}>
        <View style={{ flexDirection: 'row',}}>
            <View>
                <View style={styles.container}>
                <View style={styles.bubble}>
                    <View style={{ maxWidth: '85%', marginRight: 8,}}>
                        <Text style={styles.text}>{message}</Text>
                    </View>
                    <Text style={ styles.time }>10:04</Text>
                </View>
                </View>
            </View>
        </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-end', // Align to the left side
    marginBottom: 0, // Add margin as needed
  },
  bubble: {
    backgroundColor: my_yellow_3,
    borderRadius: 10,
    padding: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: '100%', // Adjust the width as per your design
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    color: '#000',
  },
  time: {
    fontSize: 10,
    color: '#000',
    fontWeight: '300',
    marginTop: 4,
    alignSelf: 'flex-end', // Align the timestamp to the right side
  },
});

export default ChatBubbleRight;
