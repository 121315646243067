import { useEffect, useState } from "react";
import { Dimensions } from "react-native";

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(Dimensions.get('window'));
  
    useEffect(() => {
      const updateWindowDimensions = () => {
        setWindowDimensions(Dimensions.get('window'));
        
        console.log('asdsad', Dimensions.get('window'))
      };
  
      Dimensions.addEventListener('change', updateWindowDimensions);
  
      return () => {
        Dimensions.removeEventListener('change', updateWindowDimensions);
      };
    }, []);

    windowDimensions.width = windowDimensions.width > 430 ? 430: windowDimensions.width
  
    return windowDimensions;
  };