import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Home from '../home/Home';
import Liked from '../liked/Liked';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { View } from 'react-native';
import { my_pink, my_yellow } from '../../helper/Colors';
import Chat from '../chat/Chat';

const Tab = createBottomTabNavigator();

export default function MyTabs() {
  return (
    <Tab.Navigator>
      <Tab.Screen name="Home" component={Home} 
        options={{
            headerTitle: 'Near You',
            headerLeft: () => {
                return (
                    <View style={{ backgroundColor: my_yellow, padding: 9, marginLeft: 20, borderWidth: 1, borderRightWidth: 3, borderBottomWidth: 2, borderRadius: 8,  }}>
                        <FontAwesome name="bars" size={24} color={'black'} />
                    </View>
                )
            },
            headerRight: () => {
                return (
                    <View style={{ backgroundColor: my_pink, padding: 9, marginRight: 20, borderWidth: 1, borderRightWidth: 3, borderBottomWidth: 2, borderRadius: 8,  }}>
                        <FontAwesome name="cogs" size={24} color={'black'} />
                    </View>
                )
            },
            tabBarIcon: ({color, size}) => {
                return <MaterialCommunityIcons name="home" size={size} color={color} />;
            }
        }}
      />
      <Tab.Screen name="Liked" component={Liked} 
        options={{
            headerLeft: () => {
                return (
                    <View style={{ backgroundColor: my_yellow, padding: 9, marginLeft: 20, borderWidth: 1, borderRightWidth: 3, borderBottomWidth: 2, borderRadius: 8,  }}>
                        <FontAwesome name="bars" size={24} color={'black'} />
                    </View>
                )
            },
            headerRight: () => {
                return (
                    <View style={{ backgroundColor: my_pink, padding: 9, marginRight: 20, borderWidth: 1, borderRightWidth: 3, borderBottomWidth: 2, borderRadius: 8,  }}>
                        <FontAwesome name="cogs" size={24} color={'black'} />
                    </View>
                )
            },
            tabBarIcon: ({color, size}) => {
                return <MaterialCommunityIcons name="heart" size={size} color={color} />;
            }
        }}
      />
      <Tab.Screen name="Chat" component={Chat} 
        options={{
            tabBarIcon: ({color, size}) => {
                return <MaterialCommunityIcons name="chat" size={size} color={color} />;
            }
        }}
      />
    </Tab.Navigator>
  );
}