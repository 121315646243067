import { ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import TopBar from './TopBar'
import CardLiked from './CardLiked'

export default function Liked() {
  return (
    <View style={ styles.container }>
        <TopBar />
        <ScrollView
            contentContainerStyle={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                alignContent: 'center',
                flexWrap: 'wrap',
            }}
        >
            <CardLiked 
                itemWidth={'45%'}
                name={'Jasmine'} 
                age={'24'} 
                city={'Jakarta'}
                country={'Indonesia'}
                img={require('../../assets/images/jasmine.png')}
            />

            <CardLiked 
                itemWidth={'45%'}
                name={'Galadriel'} 
                age={'27'} 
                city={'Jakarta'}
                country={'Indonesia'}
                img={require('../../assets/images/galadriel.png')}
            />

            <CardLiked 
                itemWidth={'45%'}
                name={'Jasmine'} 
                age={'24'} 
                city={'Jakarta'}
                country={'Indonesia'}
                img={require('../../assets/images/jasmine.png')}
            />

            <CardLiked 
                itemWidth={'45%'}
                name={'Galadriel'} 
                age={'27'} 
                city={'Jakarta'}
                country={'Indonesia'}
                img={require('../../assets/images/galadriel.png')}
            />

            <CardLiked 
                itemWidth={'45%'}
                name={'Jasmine'} 
                age={'24'} 
                city={'Jakarta'}
                country={'Indonesia'}
                img={require('../../assets/images/jasmine.png')}
            />

            <CardLiked 
                itemWidth={'45%'}
                name={'Galadriel'} 
                age={'27'} 
                city={'Jakarta'}
                country={'Indonesia'}
                img={require('../../assets/images/galadriel.png')}
            />
        </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
    container: {
        flex: 1, 
        paddingTop: 20, 
    }
})