import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import { my_pink } from '../../helper/Colors';

const ChatBubbleLeft = ({ message, username, pic }) => {
  return (
    <View style={{ flexDirection: 'row',marginTop: 10}}>
        <View
            style={{
                width: 50,
                height: 50,
                borderRadius: 60,
                overflow: 'hidden',
                marginRight: 8, 
            }}
        >
            <Image 
                source={ pic} 
                style={{
                    width: '100%',
                    height: '100%',
                    resizeMode: 'cover'
                }}
            />
        </View>
        <View>
            <View><Text>{username}</Text></View>
            <View style={styles.container}>
            <View style={styles.bubble}>
                <View style={{ maxWidth: '80%',}}>
                    <Text style={styles.text}>{message}</Text>
                </View>
                <Text style={ styles.time }>10:04</Text>
            </View>
            </View>
        </View>
    </View>
    
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start', // Align to the left side
    marginBottom: 0, // Add margin as needed
  },
  bubble: {
    backgroundColor: my_pink,
    borderRadius: 10,
    padding: 8,
    maxWidth: '100%', // Adjust the width as per your design
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    color: '#000',
    marginRight: 8,
  },
  time: {
    fontSize: 10,
    color: '#000',
    fontWeight: '300',
    marginTop: 4,
    alignSelf: 'flex-end', // Align the timestamp to the right side
  },
});

export default ChatBubbleLeft;
