// In App.js in a new project

import * as React from 'react';
import { View, Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Home from '../screens/home/Home';
import MyTabs from '../screens/MyTabs/MyTabs';


const Stack = createNativeStackNavigator();

function MyRoutes() {
  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen name="MyTabs" component={MyTabs} 
            options={{
                headerShown: false,
            }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default MyRoutes;