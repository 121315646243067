import { Image, SafeAreaView, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native'
import React, { useEffect } from 'react'
import { my_pink, my_pink_2, my_yellow_2 } from '../../helper/Colors';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import ChatBubbleLeft from './ChatBubbleLeft';
import ChatBubbleRight from './ChatBubbleRight';


export default function Chat({route,navigation}) {
    useEffect( () => {
        navigation.setOptions({
            headerTitle: () => {
                return (
                    <View style={{ flexDirection: 'row'}}>
                        <View
                            style={{
                                width: 50,
                                height: 50,
                                borderRadius: 60,
                                overflow: 'hidden',
                                marginRight: 8, 
                            }}
                        >
                            <Image 
                                source={ require('../../assets/images/jasmine.png')} 
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    resizeMode: 'cover'
                                }}
                            />
                        </View>
                        <View>
                            <Text style={{ fontSize: 14, fontWeight: '600'}}>Jasmine</Text>
                            <Text style={{ fontSize: 12, fontWeight: '500', color: my_yellow_2}}>Online</Text>
                        </View>
                    </View>
                )
            },
            headerRight: () => {
                return (
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between',}}>
                        <View style={{ flexDirection: 'row', marginRight: 8,}}>
                            <MaterialCommunityIcons name='phone-outline' size={28} style={{ marginRight: 8}}/>
                            <MaterialCommunityIcons name='video-outline' size={28}  style={{ marginRight: 8}}/>
                        </View>
                        <View>
                            <MaterialCommunityIcons name='dots-vertical' size={28} />
                        </View>
                    </View>
                )
            },
        });
    }, [])
  return (
    <SafeAreaView
        style={ styles.container }
    >
        <ScrollView contentContainerStyle={{ marginHorizontal: 20, paddingBottom: 20, }}>
            <ChatBubbleRight message={'Hey, what’s up!'} />
            <ChatBubbleLeft message={'Don’t forget to attend our conference at 4 PM'} pic={require('../../assets/images/jasmine.png')} username={'Jasmine'}/>
            <ChatBubbleRight message={'Hey, what’s up!'} />
            <ChatBubbleLeft message={'Don’t forget to attend our conference at 4 PM'} pic={require('../../assets/images/jasmine.png')} username={'Jasmine'}/>

            <ChatBubbleRight message={'Hey, what’s up!'} />
            <ChatBubbleLeft message={'Don’t forget to attend our conference at 4 PM'} pic={require('../../assets/images/jasmine.png')} username={'Jasmine'}/>
            <ChatBubbleRight message={'Hey, what’s up!'} />
            <ChatBubbleLeft message={'Don’t forget to attend our conference at 4 PM'} pic={require('../../assets/images/jasmine.png')} username={'Jasmine'}/>

            <ChatBubbleRight message={'Hey, what’s up!'} />
            <ChatBubbleLeft message={'Don’t forget to attend our conference at 4 PM'} pic={require('../../assets/images/jasmine.png')} username={'Jasmine'}/>
            <ChatBubbleRight message={'Hey, what’s up!'} />
            <ChatBubbleLeft message={'Don’t forget to attend our conference at 4 PM'} pic={require('../../assets/images/jasmine.png')} username={'Jasmine'}/>
        </ScrollView>
            <View style={{ borderWidth: 1, borderRadius: 10, borderRightWidth: 5, borderBottomWidth: 3,
                marginTop: 10,
                marginBottom: 10,
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingHorizontal: 10,
                paddingVertical: 10,
                marginHorizontal: 20,
                alignItems: 'center',
            }}>
                <View style={{ flexDirection: 'row', width: '75%'}}>
                    <MaterialCommunityIcons name='emoticon-happy-outline' size={24} style={{ marginRight: 10, }}/>
                    <TextInput
                        placeholder='Type something'
                        style={{
                            width: '100%'
                        }}
                    />
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '25%', alignItems: 'center',}}>
                    <View>
                        <MaterialCommunityIcons name='camera-outline' size={24}/>
                    </View>
                    <View style={{ width: 40, height: 40, borderRadius: 50, borderWidth: 1, alignItems: 'center', justifyContent: 'center',}}>
                        <MaterialCommunityIcons name='microphone-outline' size={24}/>
                    </View>
                </View>
            </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    }
})