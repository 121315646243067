import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { my_blue } from '../../helper/Colors'

export default function TopBar() {
  return (
    <View style={
            { 
                flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', paddingVertical: 18, 
                marginHorizontal: 20,  
                backgroundColor: 'white', 
                borderWidth: 1,
                borderRightWidth: 3,
                borderBottomWidth: 2,
                borderRadius: 8,
            }
        }>
        <View style={{ backgroundColor: undefined, paddingHorizontal: 15, borderRadius: 8, }}>
            <Text style={{ textAlign: 'center', paddingVertical: 6, fontSize: 14, }}>Active</Text>
        </View>
        <View style={{ backgroundColor: undefined, paddingHorizontal: 15, borderRadius: 8, }}>
            <Text style={{ textAlign: 'center', paddingVertical: 6, fontSize: 14, }}>New</Text>
        </View>
        <View style={{ backgroundColor: undefined, paddingHorizontal: 15, borderRadius: 8, }}>
            <Text style={{ textAlign: 'center', paddingVertical: 6, fontSize: 14, }}>Nearby</Text>
        </View>
        <View style={{ backgroundColor: my_blue, paddingHorizontal: 15, borderRadius: 8, }}>
            <Text style={{ textAlign: 'center', paddingVertical: 6, fontSize: 14, color: 'white' }}>Active</Text>
        </View>
    </View>
  )
}

const styles = StyleSheet.create({})