import { Image, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { my_pink, my_pink_2 } from '../../helper/Colors';

export default function CardLiked({
    name,
    age,
    city,
    country,
    img,
    itemWidth,
}) {
  return (
        
        <View style={ [styles.container, { width: itemWidth, }] }>
        <View style={ styles.containerImg}>
            <Image source={
                img
            } 
                style={ styles.img }
            />
        </View>
     

      <View style={ styles.containerInfo }>
        <View>
            <View><Text style={{ fontSize: 14, fontWeight: '600', marginBottom: 5, }}>{name}, {age}</Text></View>
            <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                <MaterialIcons name='location-on' color={'black'} size={16} />
                <Text style={{ fontSize: 10, }}>{city}, {country}</Text>
            </View>
        </View>

        <View>
            <View style={{ backgroundColor: my_pink_2, borderWidth: 1, borderRightWidth: 3,borderBottomWidth: 2, borderRadius: 8, padding: 4, }}>
                <MaterialCommunityIcons name='heart' color={'white'} size={15} />
            </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        paddingTop: 20,
        paddingBottom: 15,
        paddingHorizontal: 10,
        borderRadius: 18,
        borderRightWidth: 4,
        borderBottomWidth: 2,
        backgroundColor: my_pink,
        marginVertical: 10,
        marginLeft: 10,
    },
    containerImg: {
        height: 182,
        borderRadius: 8,
        borderWidth: 1,
        overflow: 'hidden',
        marginBottom: 12,
    },
    img: {
        height: '100%',
        width: '100%',
        resizeMode: 'cover'
    },
    containerActions: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: -45,
        marginBottom: 15,
    },
    containerInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    }
})