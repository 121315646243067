import { Dimensions, SafeAreaView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { useTheme } from 'react-native-paper';
import CardHome from './CardHome';
import { ScrollView } from 'react-native';

export default function Home() {
    const theme = useTheme();
    const itemWidth = Dimensions.get('window').width * 0.9; // Adjust the item width as desired
    
  return (
    <SafeAreaView style={{ flex: 1,  backgroundColor: '#FFFFFF', paddingTop: 20,  }}>
        <ScrollView
            horizontal
            pagingEnabled
            snapToInterval={itemWidth + 20}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center', }}
        >
            <CardHome itemWidth={itemWidth} name='Jasmine' age='24' city='Jakarta' country='Indonesia' img={require('../../assets/images/jasmine.png')} />
            <CardHome itemWidth={itemWidth} name='Galadriel' age='27' city='Jakarta' country='Indonesia' img={require('../../assets/images/galadriel.png')} />
        </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({})