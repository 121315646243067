import { StatusBar } from 'expo-status-bar';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import MyRoutes from './routes/MyRoutes';
import { MD3LightTheme as DefaultTheme, PaperProvider } from 'react-native-paper';
import { useWindowDimensions } from './hooks/useWindowDimensions';
const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: 'tomato',
    secondary: 'yellow',
  },
};

export default function App() {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  return (
    <PaperProvider>
      <View style={{ alignSelf: 'center'}}>
        <View style={{ width: windowWidth, height: windowHeight-10}}>
          <MyRoutes />
        </View>
      </View>
    </PaperProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
